import { FormManager } from 'react-form-state-manager';
import { useTranslation } from 'react-i18next';
import keyBy from 'lodash/keyBy';

import {
  EditRegistrationInput, EditUpgradeInput, ParticipantFieldScope, ParticipantFieldType, RegistrationInput,
} from '__generated__/graphql';
import { ErrorBag, filterPrefix } from '../helpers';
import { getDisabledChoices } from '../ParticipantFields/helpers';
import ParticipantFieldInput from '../ParticipantFields/ParticipantFieldInput';
import UI from '../UI';

interface ParticipantField {
  id: string;
  title: string;
  description?: string | null;
  type: ParticipantFieldType;
  scope: ParticipantFieldScope;
  required_promotions: {
    id: string;
  }[];
  enabled_choices: {
    id: string;
    title: string;
    position: number;
    enabled: boolean;
  }[];
  required: boolean;
  config?: string | null;
  has_external_validation: boolean;
}

interface ParticipantFieldEntry {
  id?: string | null;
  field: {
    id: string;
  };
  value?: string | null;
  choice_entries: {
    id?: string | null;
    choice: {
      id: string;
      title: string;
      position: number;
      enabled: boolean;
    };
  }[];
}

interface Upgrade {
  id: string;
  product: {
    id: string;
    title: string;
    is_ticket_fee: boolean;
    donation: boolean;
    active_product_variants: {
      id: string;
      title: string;
      is_sold_out: boolean;
      position: number;
    }[];
  };
  promotion: {
    id: string;
    title?: string | null;
  };
  product_variant?: {
    id: string;
    title: string;
    is_sold_out: boolean;
    position: number;
  } | null;
  participant_fields: ParticipantField[];
  participant_field_entries?: ParticipantFieldEntry[];
}

export interface EditUpgradeFormProps {
  form: FormManager<EditRegistrationInput>;
  upgrade: Upgrade;
  formKey: string;
  errors?: ErrorBag;
  registration?: RegistrationInput;
}

const EditUpgradeForm = ({
  form, upgrade, formKey, errors = {}, registration,
}: EditUpgradeFormProps) => {
  const { t } = useTranslation('common');

  const formValue: EditUpgradeInput = form.get(formKey);
  const keyedFields = keyBy(upgrade.participant_fields, 'id');

  /** Field entries that are already present on the upgrade */
  const existingFieldEntries = keyBy(upgrade.participant_field_entries || [], 'id');

  return (
    <UI.FormGrid>
      <UI.Legend>
        <UI.Delimit>
          {upgrade.product.title}
          {upgrade.promotion.title}
        </UI.Delimit>
      </UI.Legend>

      {upgrade.product.active_product_variants.length > 0 && (
        <UI.InputGroup
          sc={{
            valid: !errors[`${formKey}.product_variant.id`],
            touched: form.getTouched(`${formKey}.product_variant`),
          }}
        >
          <UI.InputLabel htmlFor="ProductVariant">
            {t('edit_registration_form.variant')}
            {' '}
            <UI.RequiredMark />
          </UI.InputLabel>
          <UI.Select
            {...form.select(
              `${formKey}.product_variant.id`,
              upgrade.product.active_product_variants.map(({ id }) => id),
            )}
            id="ProductVariant"
          >
            {!formValue.product_variant?.id && (
              <option value={-1} disabled>
                {t('edit_registration_form.make_a_choice')}
              </option>
            )}
            {upgrade.product.active_product_variants.map((productVariant, index) => (
              <option
                {...form.option(index)}
                key={index}
                disabled={upgrade.product_variant?.id !== productVariant.id && productVariant.is_sold_out}
              >
                {productVariant.title}
                {upgrade.product_variant?.id !== productVariant.id && productVariant.is_sold_out
                  && ` • ${t('edit_registration_form.unavailable')}`}
              </option>
            ))}
          </UI.Select>
          <UI.ErrorMessages attribute={upgrade.product.title} errors={errors[`${formKey}.product_variant.id`]} />
        </UI.InputGroup>
      )}

      {formValue.fields.map((value, fieldIndex) => (
        <ParticipantFieldInput
          value={value}
          onChange={(newValue) => form.set(`${formKey}.fields.${fieldIndex}`, {
            ...formValue.fields[fieldIndex],
            ...newValue,
          })}
          onBlur={() => form.setTouched(`${formKey}.fields.${fieldIndex}`, true)}
          touched={form.getTouched(`${formKey}.fields.${fieldIndex}`)}
          field={keyedFields[value.participant_field.id]}
          disabledChoices={existingFieldEntries[upgrade.id] ? getDisabledChoices(
            keyedFields[value.participant_field.id],
            existingFieldEntries[upgrade.id],
          ) : []}
          errors={filterPrefix(errors, `${formKey}.fields.${fieldIndex}`)}
          registration={registration}
          key={value.participant_field.id}
        />
      ))}
    </UI.FormGrid>
  );
};

export default EditUpgradeForm;
