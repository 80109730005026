import { CheckCircle, Circle } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import {
  DiscountType,
  GetEventQuery as GetEvent,
} from '__generated__/graphql';

import { Event, getTicketsForSale } from './helpers';
import { Quantities } from './useQuantities';
import { useDiscountFormatter } from '../../common/Common/DiscountValue';
import UI from '../../common/UI';
import useLocale from '../../common/useLocale';

 type Discount = GetEvent['event']['ticket_categories'][0]['tickets_for_sale']['0']['current_discount'];

export interface DiscountInfoProps {
  event: Event;
  discount: Discount;
  quantities: Quantities;
}

const DiscountInfo = ({ event, discount, quantities }: DiscountInfoProps) => {
  // Explicitly use frontend namespace, because this component is also used in the participant dashboard
  const { t } = useTranslation('frontend');
  const { formatNumber } = useLocale();
  const formatDiscount = useDiscountFormatter();

  const sellables = [...getTicketsForSale(event), ...event.products_for_sale];

  const { quantity, applicableItems } = sellables.reduce((data, sellable) => {
    if (sellable.current_discount?.id === discount.id) {
      data.quantity += sellable.promotions_for_sale.reduce(
        (sum, promotion) => sum + (quantities.promotions[promotion.id] || 0),
        0,
      );

      data.applicableItems += 1;
    }

    return data;
  }, { quantity: 0, applicableItems: 0 });

  if (discount.levels.length === 1 && discount.levels[0].quantity <= 1) {
    return null;
  }

  return (
    <UI.Info>
      <UI.Div sc={{ mb: 1 }}>
        <UI.Delimit>
          <UI.Strong sc={{ color: 'gray.800' }}>
            {discount.title}
          </UI.Strong>
          {applicableItems > 1 && t('combinations_allowed')}
        </UI.Delimit>
      </UI.Div>
      {discount.levels.map((level) => (
        <UI.Div
          sc={{ color: quantity >= level.quantity ? 'gray.800' : 'gray.500' }}
          key={level.id}
        >
          <UI.Icon
            sc={{ color: quantity >= level.quantity ? 'success' : 'gray.400', mr: 1 }}
          >
            {quantity < level.quantity && <Circle strokeWidth={2} />}
            {quantity >= level.quantity && <CheckCircle strokeWidth={2} />}
          </UI.Icon>
          {' '}
          <Trans i18nKey="frontend:n_or_more" values={{ n: formatNumber(level.quantity) }}>
            <UI.Strong />
          </Trans>
          <UI.Span sc={{ muted: true }}> = </UI.Span>
          <Trans i18nKey="frontend:x_off" values={{ x: formatDiscount({ type: discount.type, value: level.value }) }}>
            <UI.Strong />
          </Trans>
          {discount.type === DiscountType.absolute && ` (${t('each')})`}
        </UI.Div>
      ))}
    </UI.Info>
  );
};

export default DiscountInfo;
