import { useTranslation } from 'react-i18next';

import { GetCheckoutSummaryQuery as GetCheckoutSummary } from '__generated__/graphql';

import { Event } from './helpers';
import PlatformFeeLine from '../../common/Purchases/PlatformFeeLine';
import PurchaseLine from '../../common/Purchases/PurchaseLine';
import UI from '../../common/UI';
import useLocale from '../../common/useLocale';

export interface CheckoutSummaryProps {
  event: Event;
  summary: GetCheckoutSummary['checkoutSummary'];
  downPayment?: boolean;
  loading?: boolean;
}

const CheckoutSummary = ({ event, summary, downPayment, loading }: CheckoutSummaryProps) => {
  // Explicitly use frontend namespace, because this component is also used in the participant dashboard
  const { t } = useTranslation('frontend');
  const { formatCurrency, formatDate, parseDate } = useLocale();
  const isDownPayment = downPayment && summary.down_payment_amount < summary.amount;

  return (
    <UI.GridContainer sc={{ gutter: 0.75 }}>
      <UI.GridContainer sc={{ gutter: 0.5 }}>
        {summary.tickets.map((ticket, index) => (
          <PurchaseLine ticket={ticket} key={index} />
        ))}
        {summary.products.map((product, index) => (
          <PurchaseLine product={product} hideIcon={summary.tickets.length === 0} key={index} />
        ))}
      </UI.GridContainer>
      {!isDownPayment && (
        <>
          {summary.passed_on_fee > 0 && (
            <>
              <UI.HR />
              <PlatformFeeLine fee={summary.passed_on_fee} />
            </>
          )}
          <UI.HR sc={{ borderWidth: 2 }} />
          <UI.GridContainer sc={{ gutter: 0.5 }}>
            <UI.GridContainer sc={{ columns: '2fr 1fr' }}>
              <UI.Div sc={{ fontSize: 3, fontWeight: 500 }}>
                {t('total')}
              </UI.Div>
              <UI.Div sc={{ fontSize: 3, strong: true, muted: loading, textAlign: 'right' }}>
                {formatCurrency(summary.amount + summary.passed_on_fee)}
              </UI.Div>
            </UI.GridContainer>
            {(summary.vat_amount + summary.passed_on_fee_vat_amount) > 0 && (
              <UI.GridContainer sc={{ columns: '2fr 1fr', muted: true }}>
                <UI.Div>
                  {t('included_taxes')}
                </UI.Div>
                <UI.Div sc={{ textAlign: 'right' }}>
                  {formatCurrency(summary.vat_amount + summary.passed_on_fee_vat_amount)}
                </UI.Div>
              </UI.GridContainer>
            )}
          </UI.GridContainer>
        </>
      )}
      {isDownPayment && (
        <>
          {(summary.passed_on_fee + summary.down_payment_passed_on_fee) > 0 && (
            <>
              <UI.HR />
              <PlatformFeeLine fee={summary.passed_on_fee + summary.down_payment_passed_on_fee} />
            </>
          )}
          <UI.HR sc={{ borderWidth: 2 }} />
          <UI.GridContainer sc={{ gutter: 0.5 }}>
            <UI.GridContainer sc={{ columns: '2fr 1fr' }}>
              <UI.Div sc={{ fontSize: 3, fontWeight: 500 }}>
                {t('due_now')}
              </UI.Div>
              <UI.Div sc={{ fontSize: 3, strong: true, muted: loading, textAlign: 'right' }}>
                {formatCurrency(summary.down_payment_amount + summary.passed_on_fee)}
              </UI.Div>
            </UI.GridContainer>
            <UI.GridContainer sc={{ columns: '2fr 1fr' }}>
              <UI.Div>
                {t('due_on', {
                  date: formatDate(
                    parseDate(
                      event.down_payment_deadline,
                      { timezone: 'UTC', format: 'internal_date' },
                    ),
                    { format: 'display_date' },
                  ),
                })}
              </UI.Div>
              <UI.Div sc={{ textAlign: 'right' }}>
                {formatCurrency(
                  summary.amount - summary.down_payment_amount + summary.down_payment_passed_on_fee,
                )}
              </UI.Div>
            </UI.GridContainer>
            {(summary.vat_amount
              + summary.passed_on_fee_vat_amount + summary.down_payment_passed_on_fee_vat_amount) > 0 && (
              <UI.GridContainer sc={{ columns: '2fr 1fr', muted: true }}>
                <UI.Div>
                  {t('included_taxes')}
                </UI.Div>
                <UI.Div sc={{ textAlign: 'right' }}>
                  {formatCurrency(summary.vat_amount
                    + summary.passed_on_fee_vat_amount + summary.down_payment_passed_on_fee_vat_amount)}
                </UI.Div>
              </UI.GridContainer>
            )}
          </UI.GridContainer>
        </>
      )}
    </UI.GridContainer>
  );
};

export default CheckoutSummary;
