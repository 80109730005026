import { ArrowDown, ExternalLink, Search, X, XCircle } from 'react-feather';
import { ChangeEvent, ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetCharitiesQuery as GetCharities } from '__generated__/graphql';

import { ErrorBag } from '../helpers';
import GetCharitiesQuery from './GetCharitiesQuery';
import UI from '../UI';
import config from '../../config';
import useLocale from '../useLocale';
import useProject from '../useProject';
import useQuery from '../../api/useQuery';

export type Charity = Omit<GetCharities['project']['charities']['data'][0], '__typename'>;

export interface CharityPickerProps {
  charity?: Charity | null;
  eventId?: string;
  onChange: (charity: Charity | null) => void;
  description?: ReactNode;
  showInitialCharities?: boolean;
  required?: boolean;
  touched?: any;
  errors?: ErrorBag;
}

const CharityPicker = ({
  charity: selectedCharity,
  eventId,
  onChange,
  description,
  showInitialCharities = true,
  required = false,
  touched,
  errors,
}: CharityPickerProps) => {
  const { t } = useTranslation('common');
  const { locale } = useLocale();
  const project = useProject();

  const perPage = 5;
  const [limit, setLimit] = useState(perPage);
  const searchRef = useRef<HTMLInputElement>();
  const [search, setSearch] = useState('');

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setCharity(null);
    setSearch(event.target.value);
    setLimit(perPage);
  };

  const resetSearch = () => {
    if (searchRef.current) {
      searchRef.current.value = '';
    }
    setSearch('');
    setLimit(perPage);
  };

  const setCharity = (charity: Charity | null) => {
    onChange(charity);
  };

  const { data, loading } = useQuery(
    GetCharitiesQuery,
    {
      variables: {
        project_id: project.id,
        event_id: eventId,
        search,
        per_page: limit,
        page: 1,
        random: true,
      },
      skip: !showInitialCharities && !search,
      fetchPolicy: 'cache-first', // Produces the least amount of flickering/reordering of the results list
    },
  );

  return (
    <>
      <UI.InputGroup sc={{ valid: !errors?.charity, touched: !!touched?.charity }}>
        <UI.InputLabel htmlFor="CharityPickerSearch">
          {t('fundraising_form.find_your_charity')}
          {' '}
          {required && <UI.RequiredMark />}
        </UI.InputLabel>
        {description}
        <UI.FormGrid sc={{ gutter: 0.5 }}>
          {selectedCharity && (
            <UI.Checkbox
              checked
              onChange={() => setCharity(null)}
              sc={{
                box: true,
                block: true,
                checkboxTop: selectedCharity.logo_url ? 22 : undefined,
              }}
            >
              <UI.GridContainer
                sc={{
                  columns: selectedCharity.logo_url ? '44px 1fr 16px' : '1fr 16px',
                  alignVertical: !selectedCharity.description ? 'center' : undefined,
                  gutter: 0.25,
                }}
              >
                {selectedCharity.logo_url && (
                  <UI.Div sc={{ padding: 0.75 }}>
                    <img
                      src={selectedCharity.logo_url}
                      alt={selectedCharity.title}
                      style={{ display: 'block', objectFit: 'contain', width: 35, height: 35 }}
                    />
                  </UI.Div>
                )}
                <UI.Div>
                  <UI.Span style={{ fontWeight: 500 }}>
                    {selectedCharity.title}
                  </UI.Span>
                  {selectedCharity.description && (
                    <UI.InputDescription sc={{ color: 'gray.500' }}>
                      {selectedCharity.description}
                    </UI.InputDescription>
                  )}
                </UI.Div>
                <UI.Div>
                  <UI.Icon sc={{ muted: true }}>
                    <X />
                  </UI.Icon>
                </UI.Div>
              </UI.GridContainer>
            </UI.Checkbox>
          )}
          {!selectedCharity && (
            <UI.InputWrapper>
              <UI.Span>
                <UI.Icon>
                  <Search />
                </UI.Icon>
              </UI.Span>
              <UI.DebouncedInput
                type="text"
                value={search}
                onChange={handleSearch}
                ref={searchRef}
                placeholder={t('fundraising_form.search_charities')}
                sc={{ px: 5 }}
                id="CharityPickerSearch"
              />
              {search && (
                <UI.Span>
                  <UI.A onClick={() => resetSearch()} sc={{ color: 'gray.400' }}>
                    <UI.Icon>
                      <XCircle />
                    </UI.Icon>
                  </UI.A>
                </UI.Span>
              )}
            </UI.InputWrapper>
          )}
          {!selectedCharity && (
            <UI.FormGrid sc={{ gutter: 0.25 }}>
              {data?.project.charities.total === 0 && (
                <UI.Div sc={{ muted: true }}>
                  {t('fundraising_form.no_charities_found')}
                </UI.Div>
              )}
              {(search || showInitialCharities) && data?.project.charities.data.map((charity) => (
                <UI.Radio
                  checked={selectedCharity?.id === charity.id}
                  onChange={() => setCharity(charity)}
                  sc={{ box: true, radioPositionV: charity.logo_url ? 'center' : undefined }}
                  key={charity.id}
                >
                  <UI.GridContainer
                    sc={{
                      columns: charity.logo_url ? '44px 1fr' : '1fr',
                      alignVertical: !charity.description ? 'center' : undefined,
                      gutter: 0.25,
                    }}
                  >
                    {charity.logo_url && (
                      <UI.Div sc={{ padding: 0.75 }}>
                        <img
                          src={charity.logo_url}
                          alt={charity.title}
                          style={{ display: 'block', objectFit: 'contain', width: 35, height: 35 }}
                        />
                      </UI.Div>
                    )}
                    <UI.Div style={{ minWidth: 0 }}>
                      <UI.Span style={{ fontWeight: 500 }}>
                        {charity.title}
                      </UI.Span>
                      {charity.description && (
                        <UI.InputDescription sc={{ color: 'gray.500' }}>
                          <UI.Truncate>
                            {charity.description}
                          </UI.Truncate>
                        </UI.InputDescription>
                      )}
                    </UI.Div>
                  </UI.GridContainer>
                </UI.Radio>
              ))}
            </UI.FormGrid>
          )}
          {!selectedCharity && data?.project.charities.total > limit && (
            <UI.Button
              onClick={() => setLimit((limit) => limit + perPage)}
              sc={{ blank: true, size: 'sm', color: 'gray.600', loading }}
            >
              <UI.Icon>
                <ArrowDown />
              </UI.Icon>
              {' '}
              {t('fundraising_form.show_more')}
            </UI.Button>
          )}
        </UI.FormGrid>
        <UI.ErrorMessages attribute={t('fundraising_form.charity')} errors={errors?.charity} />
      </UI.InputGroup>
      {!selectedCharity && (
        <UI.Div>
          <UI.Span sc={{ muted: true }}>
            {t('fundraising_form.charity_not_listed')}
          </UI.Span>
          {' '}
          <UI.A
            href={config.supporta.signUpUrl[locale]}
            target="_blank"
            rel="noopener noreferrer"
            sc={{ noWrap: true }}
          >
            {t('fundraising_form.sign_up_charity')}
            {' '}
            <UI.Icon>
              <ExternalLink />
            </UI.Icon>
          </UI.A>
        </UI.Div>
      )}
    </>
  );
};

export default CharityPicker;
