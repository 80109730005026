import { useTranslation } from 'react-i18next';

import UI from '../../common/UI';

interface SoldOutLabelProps {
  title?: string;
}

const SoldOutLabel = ({ title }: SoldOutLabelProps) => {
  // Explicitly use frontend namespace, because this component is also used in the participant dashboard
  const { t } = useTranslation('frontend');

  return (
    <UI.Button
      sc={{
        brand: 'gray.100',
        color: 'gray.500',
      }}
      style={{
        pointerEvents: 'none',
      }}
      aria-label={t('sold_out_item', { title })}
    >
      {t('sold_out')}
    </UI.Button>
  );
};

export default SoldOutLabel;
