import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { assetUrl } from './helpers';
import UI from './UI';
import config from '../config';

const Container = styled(UI.Div)`
  ${({ theme }) => css`
    min-width: 320px;
    max-width: ${theme.breakpoints.md}px;
    margin: 0 auto;
    padding: ${theme.gutter * 0.75}px;

    @media ${theme.devices.md} {
      padding: ${theme.gutter}px;
    }
  `}
`;

const EventNotFound = () => {
  const { t } = useTranslation('common');

  return (
    <Container sc={{ py: 6 }}>
      <img src={assetUrl('images/compass.svg')} alt={t('event_not_found.event_not_found')} height={120} />
      <UI.H1 sc={{ my: 4 }}>
        <UI.Span sc={{ color: 'secondary.900' }}>
          {t('event_not_found.event_not_found')}
        </UI.Span>
      </UI.H1>
      <UI.Paragraph sc={{ mb: 0 }}>
        {t('event_not_found.event_not_found_description')}
      </UI.Paragraph>
      <UI.HR sc={{ my: 6 }} />
      <UI.Div sc={{ textAlign: 'center' }}>
        <a href={config.baseUrl} target="_blank" rel="noopener noreferrer">
          <img src={assetUrl('images/logo-color.svg')} alt={t('atleta')} height={14} />
        </a>
      </UI.Div>
    </Container>
  );
};

export default EventNotFound;
